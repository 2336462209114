import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  margin: 30px auto;
  position: relative;

  @media (min-width: 768px) {
    margin: 0 auto;
    margin-bottom: 30px;
    height: 320px;
  }
`;

const fadeKeyFrames = keyframes`
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
`;

const fade = css`
  animation: ${fadeKeyFrames} 2s;
`;

const efectPrimaryBackground = css`
  color: white;
  background-color: ${(props) => props.theme.colors.primary};

  transition: 0.6s ease;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: white;
  }
`;

export const Slide = styled.div`
  display: none;
  position: relative;
  width: 100%;
  height: 100%;

  &.active {
    display: block;
  }

  ${fade};
`;

export const Image = styled.img`
  background: #ddd;
  margin: 0;
  position: relative;
  max-height: 100%;
  max-width: 100%;

  @media (min-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Arrow = styled.a`
  ${efectPrimaryBackground};
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  border-radius: 50%;
`;

export const ArrowLeft = styled(Arrow)`
  left: -15px;
`;

export const ArrowRight = styled(Arrow)`
  right: -15px;
`;

export const Text = styled.p`
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 7px;

  ${efectPrimaryBackground};
`;

export const NumberText = styled.div`
  font-size: 12px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  background: white;
  border-radius: 7px;
  color: black;
  padding: 5px 10px;

  ${efectPrimaryBackground};
`;
