import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import api from '@alweb-merkator/shared/services/api';

import {
  Col,
  Produto,
  ProdutoConteudo,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoSubTitulo,
  SecaoTitulo,
  Tradutor,
  ImagemPlaceholder,
  ImagemComOverlay,
  ImagemOverlay,
  Overlay,
  LinkProduto,
} from './styles';

import ImgPlaceholderProdutos40 from '../../../assets/placeholder-produtos-40-graus.png';
import ImgPlaceholderProdutosSicc from '../../../assets/placeholder-produtos-sicc.png';
import ImgPlaceholderProdutosZero from '../../../assets/placeholder-produtos-zero-grau.png';
import ImgProdutoSicc from '../../../assets/logo-branco-sicc-produtos.svg';
import ImgProdutoZeroGrau from '../../../assets/produto-zero-grau.png';
import ImgProduto40Graus from '../../../assets/produto-40-graus.png';

const SecaoProdutos = ({ idPage, ...props }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const secaoId = t('slugs.nossosProdutos');

  const [nossosProdutos, setQuemSomos] = useState();
  const [sicc, setSicc] = useState();
  const [zero, setZero] = useState();
  const [quarenta, setQuarenta] = useState();
  const [loadingSicc, setloadingSicc] = useState(false);
  const [loadingZero, setloadingZero] = useState(false);
  const [loadingQuarenta, setloadingQuarenta] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    _handleGet();
    _handleProdSicc();
    _handleProdZero();
    _handleProdQuarenta();
  }, []);

  const _handleGet = () => {
    const dado = {
      title: t('paginas.inicial.nossosProdutos.titulo'),
      content: t('paginas.inicial.nossosProdutos.conteudo'),
      description: t('paginas.inicial.nossosProdutos.subTitulo'),
      video: t('paginas.inicial.nossosProdutos.video'),
      image_description: t('paginas.inicial.nossosProdutos.imagemDescricao'),
    };
    setQuemSomos(dado);
    setIsLoading(false);
  };

  const _handleProdSicc = async () => {
    await api.get(`/blnews/464`).then((response) => {
      const dados = response.data[0];
      const dado = {
        title: '',
        content: '',
        image: dados.image,
        image_description: dados.image_description,
      };

      if (lang !== 'pt') {
        dado.title = dados['title_' + lang];
        dado.content = dados['content_' + lang];
        dado.description = dados['description_' + lang];
      } else {
        dado.title = dados.title;
        dado.content = dados.content;
        dado.description = dados.description;
      }
      setSicc(dado);
      setloadingSicc(true);
    });
  };

  const _handleProdZero = async () => {
    await api.get(`/blnews/465`).then((response) => {
      const dadosZero = response.data[0];
      const dataZero = {
        title: '',
        content: '',
        image: dadosZero.image,
        image_description: dadosZero.image_description,
      };
      if (lang !== 'pt') {
        dataZero.title = dadosZero['title_' + lang];
        dataZero.content = dadosZero['content_' + lang];
        dataZero.description = dadosZero['description_' + lang];
        dataZero.image_description = dadosZero.image_description;
      } else {
        dataZero.title = dadosZero.title;
        dataZero.content = dadosZero.content;
        dataZero.description = dadosZero.description;
        dataZero.image_description = dadosZero.image_description;
      }
      setZero(dataZero);
      setloadingZero(true);
    });
  };

  const _handleProdQuarenta = async () => {
    await api.get(`/blnews/466`).then((response) => {
      const dadosQuarenta = response.data[0];
      const dataQuarenta = {
        title: '',
        content: '',
        image: dadosQuarenta.image,
        image_description: dadosQuarenta.image_description,
      };
      if (lang !== 'pt') {
        dataQuarenta.title = dadosQuarenta['title_' + lang];
        dataQuarenta.content = dadosQuarenta['content_' + lang];
        dataQuarenta.description = dadosQuarenta['description_' + lang];
      } else {
        dataQuarenta.title = dadosQuarenta.title;
        dataQuarenta.content = dadosQuarenta.content;
        dataQuarenta.description = dadosQuarenta.description;
      }
      setQuarenta(dataQuarenta);
      setloadingQuarenta(true);
    });
  };

  return (
    <Secao {...props} id={secaoId}>
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? '' : nossosProdutos.title} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <SecaoSubTitulo className="mb-4">
        {/* <Tradutor path={isLoading ? '' : nossosProdutos.description} /> */}
      </SecaoSubTitulo>
      {isLoading ? null : (
        <Row>
          <Col md="4" className="mb-4">
            <Produto>
              <LinkProduto
                href="https://www.sicc.com.br/"
                target="_blank"
                title={t('paginas.inicial.nossosProdutos.produtos.sicc.titulo')}
              >
                <ImagemComOverlay>
                  <ImagemPlaceholder src={ImgPlaceholderProdutosSicc} />
                  <Overlay>
                    <ImagemOverlay
                      src={ImgProdutoSicc}
                      alt={t(
                        'paginas.inicial.nossosProdutos.produtos.sicc.titulo'
                      )}
                    />
                  </Overlay>
                </ImagemComOverlay>
              </LinkProduto>
              <ProdutoConteudo
                dangerouslySetInnerHTML={{
                  __html: loadingSicc ? sicc.content : '',
                }}
              ></ProdutoConteudo>
            </Produto>
          </Col>
          <Col md="4" className="mb-4">
            <Produto>
              <LinkProduto
                href="https://www.feirazerograu.com.br/"
                target="_blank"
                title={t(
                  'paginas.inicial.nossosProdutos.produtos.zeroGraus.titulo'
                )}
              >
                <ImagemComOverlay>
                  <ImagemPlaceholder src={ImgPlaceholderProdutosZero} />
                  <Overlay>
                    <ImagemOverlay
                      src={ImgProdutoZeroGrau}
                      alt={t(
                        'paginas.inicial.nossosProdutos.produtos.zeroGraus.titulo'
                      )}
                    />
                  </Overlay>
                </ImagemComOverlay>
              </LinkProduto>
              <ProdutoConteudo
                dangerouslySetInnerHTML={{
                  __html: loadingZero ? zero.content : '',
                }}
              ></ProdutoConteudo>
            </Produto>
          </Col>
          <Col md="4" className="mb-4">
            <Produto>
              <LinkProduto
                href="https://www.feira40graus.com.br/"
                target="_blank"
                title={t(
                  'paginas.inicial.nossosProdutos.produtos.quarentaGraus.titulo'
                )}
              >
                <ImagemComOverlay>
                  <ImagemPlaceholder src={ImgPlaceholderProdutos40} />
                  <Overlay>
                    <ImagemOverlay
                      src={ImgProduto40Graus}
                      alt={t(
                        'paginas.inicial.nossosProdutos.produtos.quarentaGraus.titulo'
                      )}
                    />
                  </Overlay>
                </ImagemComOverlay>
              </LinkProduto>
              <ProdutoConteudo
                dangerouslySetInnerHTML={{
                  __html: loadingQuarenta ? quarenta.content : '',
                }}
              ></ProdutoConteudo>
            </Produto>
          </Col>
        </Row>
      )}
    </Secao>
  );
};

SecaoProdutos.propTypes = {
  idPage: PropTypes.any,
};

export default SecaoProdutos;
