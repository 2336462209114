import React from 'react';
import ThemeProviderShared from '@alweb-merkator/shared/providers/ThemeProvider';

import merkatorTheme from '../styles/themes/merkator';

const ThemeProvider = (props) => (
  <ThemeProviderShared currentTheme={merkatorTheme} {...props} />
);

export default ThemeProvider;
