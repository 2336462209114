import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoSubTitulo,
  SecaoTitulo,
  Tradutor,
} from './styles';
import FormContato from './FormContato';

const SecaoContato = ({ idPage, ...props }) => {
  const { t } = useTranslation();
  const secaoId = t('slugs.contato');

  const [contato, setContato] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    _handleGet();
  }, []);

  const _handleGet = () => {
    const dado = {
      title: t('paginas.inicial.contato.titulo'),
      content: t('paginas.inicial.contato.conteudo'),
      description: t('paginas.inicial.contato.subTitulo'),
    };

    setContato(dado);
    setIsLoading(false);
  };

  return (
    <Secao {...props} id={secaoId}>
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? '' : contato.title} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <SecaoSubTitulo className="mb-4">
        <Tradutor path={isLoading ? '' : contato.description} />
      </SecaoSubTitulo>
      {isLoading ? null : <FormContato />}
    </Secao>
  );
};

SecaoContato.propTypes = {
  idPage: PropTypes.any,
};

export default SecaoContato;
