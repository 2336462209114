import styled from 'styled-components';
import {
  Container as RsContainer,
  Row as RsRow,
  Col as RsCol,
} from 'reactstrap';

export const Container = styled(RsContainer)``;

export const Row = styled(RsRow)``;

export const Col = styled(RsCol)``;

export const FooterStyled = styled.footer`
  padding: 30px 0;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.textInBg};

  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const Logo = styled.img`
  max-width: 250px;
  margin-bottom: 15px;
`;

export const Informacoes = styled.p`
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const MediaLink = styled.a`
  display: block;
  padding: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.textInBg};

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.textInBg};
  }
`;

export const MediaLinkText = styled.span`
  margin-left: 5px;
`;

export const Icon = styled.span`
  padding: 5px;
  height: 30px;
  width: 30px;
  font-size: 15px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.textInBg};
`;
