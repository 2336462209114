import React, { useMemo } from 'react';
import CabecalhoShared from '@alweb-merkator/shared/components/CabecalhoFeiras';
import LogoImage from '../../assets/logo-merkator-potecializando-2023.svg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const Cabecalho = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  const medias = [
    {
      socialMedia: 'facebook',
      link: '//www.facebook.com/merkatorfeira',
    },
    {
      socialMedia: 'instagram',
      link: '//www.instagram.com/merkatorfeira',
    },
    {
      socialMedia: 'apple',
      link: '//apps.apple.com/us/app/merkator-feiras-e-eventos/id1487335018',
    },
    {
      socialMedia: 'googlePlay',
      link: '//play.google.com/store/apps/details?id=com.merkatorvisitante&hl=pt_BR&gl=US',
    },
  ];

  const menuItem = (text, link, subMenus = [], externalLink = false) => ({
    text: text,
    link: link,
    subMenus,
    externalLink,
  });

  const menu = useMemo(() => {
    const myMenu = [
      menuItem(t('cabecalho.quemSomos'), `/${lang}#${t('slugs.quemSomos')}`),
      menuItem(
        t('cabecalho.nossosProdutos'),
        `/${lang}#${t('slugs.nossosProdutos')}`
      ),
      menuItem(t('cabecalho.eMerkator'), `/${lang}#${t('slugs.eMerkator')}`),
      menuItem(t('cabecalho.imprensa'), `/${lang}#${t('slugs.imprensa')}`),
      menuItem(t('cabecalho.contato'), `/${lang}#${t('slugs.contato')}`),
    ];

    return myMenu;
  }, [t, lang]);

  return (
    <CabecalhoShared
      medias={medias}
      menu={menu}
      logo={LogoImage}
      mostrarSubMenu={false}
    />
  );
};

export default Cabecalho;
