import styled, { css } from 'styled-components';

export { Row, Col } from 'reactstrap';

export const formGroup = css`
  width: 100%;
  margin-bottom: 15px;
  border-width: 1px !important;
  border-top-style: outset;
  border-left-style: outset;
  padding: 10px;
  border-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};

  &::placeholder {
    color: ${(props) => props.theme.colors.primary};
    opacity: 0.8;
  }

  &:-ms-input-placeholder {
    color: ${(props) => props.theme.colors.primary};
  }

  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const Input = styled.input`
  ${formGroup};
`;

export const Select = styled.select`
  ${formGroup};
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: inline-block;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${(props) => props.theme.colors.primary} 50%
    ),
    linear-gradient(
      135deg,
      ${(props) => props.theme.colors.primary} 50%,
      transparent 50%
    );
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    background-image: linear-gradient(
        45deg,
        ${(props) => props.theme.colors.primary} 50%,
        transparent 50%
      ),
      linear-gradient(
        135deg,
        transparent 50%,
        ${(props) => props.theme.colors.primary} 50%
      );
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    outline: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

export const Option = styled.option`
  ${formGroup};
  border-left: 5px solid black;
`;

export const Textarea = styled.textarea`
  ${formGroup};
  min-height: calc(100% - 15px);
`;

export const DangerMessage = styled.p`
  color: red;
  font-size: 13px;
  margin-top: -10px;
  margin-left: 5px;
`;

export const ContainerOverRow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const TextSend = styled.p`
  color: ${(props) => props.theme.colors.primary};
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 0;
`;

export const Button = styled.button.attrs(({ className }) => ({
  className: `btn btn-primary ${className}`,
}))`
  float: right;
`;
