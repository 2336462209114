import styled from 'styled-components';
import {
  SecaoMenuItem as CompSecaoMenuItem,
  SecaoBotaoVoltar as CompSecaoBotaoVoltar,
} from '../../../components/Secao';

export { Col, Row } from 'reactstrap';
export { Tradutor } from '../../../components/I18n';

export {
  Secao,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  SecaoCabecalho,
  SecaoTitulo,
} from '../../../components/Secao';

export const SecaoMenuItem = styled(CompSecaoMenuItem)`
  font-size: 1rem;
  line-height: 2.5rem;
`;

export const SecaoBotaoVoltar = styled(CompSecaoBotaoVoltar)`
  font-size: 1rem;
  line-height: 2.5rem;
`;

export const SubTitulo = styled.h2`
  margin: 0;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textInBg};
  text-align: center;
  padding: 15px 0;
  font-weight: 700;
  margin-bottom: 30px;
`;

export const ImagemResponsiva = styled.div`
  display: block;
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  height: 100px;

  @media screen and (min-width: 375px) {
    height: 150px;
  }

  @media screen and (min-width: 768px) {
    height: 150px;
  }
`;

export const Noticia = styled.div`
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
`;

export const NoticiaImagem = styled(ImagemResponsiva)`
  display: inline-block;
  width: 35%;
  float: left;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 120px;
    margin-bottom: 10px;
  }
`;

export const NoticiaTitulo = styled.p`
  width: 65%;
  padding: 0;
  margin: 0;
  padding-left: 15px;
  display: inline-block;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Conteudo = styled.div``;

export const GridImagens = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
`;

export const Box = styled.div`
  padding: 30px;
  border: 1px solid ${(props) => props.theme.colors.borders};
`;

export const TextoAgenciaTurismoOficial = styled.span`
  font-style: italic;
`;

export const QueroFalarComAcessoria = styled.a.attrs(({ className }) => ({
  className: `btn btn-primary d-block w-100 ${className}`,
}))`
  margin: 0;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 30px;
`;

export const SectionCenter = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
