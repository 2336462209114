import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '@alweb-merkator/shared/components/Loader';
import axios from 'axios';
import InputMask from 'react-input-mask';

import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Select,
  Option,
  Textarea,
  DangerMessage,
  ContainerOverRow,
} from './styles';

import { SecaoSubTitulo, Tradutor } from '../styles';

function FormContato() {
  const { t } = useTranslation();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);

  const sendData = async (event) => {
    setModalSubmit(true);
    await axios
      .get('https://paineldoexpositor.com.br/application/sendmessage', {
        params: {
          nome: form.nome,
          telefone: form.telefone,
          email: form.email,
          assunto: form.assunto,
          setor: form.setor,
          mensagem: form.mensagem,
        },
      })
      .then(function (response) {
        setSubmitting(true);
        setModalSubmit(false);
      })
      .catch(function (error) {
        console.log(error);
        setModalSubmit(false);
      });
  };

  function handleChange(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  function hanldeValidate() {
    const errors = {};
    if (!form.nome) {
      errors.nome = '* Preencha com um nome válido';
    }
    if (!form.telefone) {
      errors.telefone = '* Preencha com um telefone válido';
    }
    if (!form.email) {
      errors.email = '* Preencha com um e-mail válido';
    }
    if (!form.assunto) {
      errors.assunto = '* Preencha com um assunto válido';
    }
    if (!form.setor) {
      errors.setor = '* Selecione um setor válido';
    }
    if (!form.mensagem) {
      errors.mensagem = '* Preencha com uma mensagem válida';
    }
    return errors;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setErrors(hanldeValidate(form));
    if (Object.keys(hanldeValidate(form)).length === 0) {
      sendData();
    }
  }

  return (
    <>
      {submitting && (
        <SecaoSubTitulo className="mb-4">
          <Tradutor path="Mensagem enviada com sucesso" />
        </SecaoSubTitulo>
      )}
      {!submitting && (
        <Form method="POST" onSubmit={(e) => handleSubmit(e)}>
          <Row>
            {modalSubmit && (
              <ContainerOverRow>
                <Loader />
              </ContainerOverRow>
            )}
            <Col md="6">
              <Input
                type="text"
                name="nome"
                id="nome"
                onChange={(e) => handleChange(e)}
                placeholder={t('paginas.inicial.contato.nome')}
              />
              {errors.nome && <DangerMessage>{errors.nome}</DangerMessage>}
              <InputMask
                type="tel"
                name="telefone"
                mask="(99) 99999-9999"
                onChange={(e) => handleChange(e)}
                placeholder={t('paginas.inicial.contato.telefone')}
                className="formGroup"
              />
              {errors.telefone && (
                <DangerMessage>{errors.telefone}</DangerMessage>
              )}
              <Input
                type="email"
                name="email"
                className="formGroup"
                onChange={(e) => handleChange(e)}
                placeholder={t('paginas.inicial.contato.email')}
              />
              {errors.email && <DangerMessage>{errors.email}</DangerMessage>}
              <Input
                type="text"
                name="assunto"
                onChange={(e) => handleChange(e)}
                placeholder={t('paginas.inicial.contato.assunto')}
              />
              {errors.assunto && (
                <DangerMessage>{errors.assunto}</DangerMessage>
              )}
              <Select
                type="text"
                name="setor"
                onChange={(e) => handleChange(e)}
              >
                <Option value="">{t('paginas.inicial.contato.setor')}</Option>
                <Option value="Departamento Comercial">
                  {t('paginas.inicial.contato.setores.departamentoComercial')}
                </Option>
                <Option value="Departamento Financeiro">
                  {t('paginas.inicial.contato.setores.departamentoFinanceiro')}
                </Option>
                <Option value="Departamento de Marketing">
                  {t('paginas.inicial.contato.setores.departamentoDeMarketing')}
                </Option>
                <Option value="Departamento de Relacionamento">
                  {t(
                    'paginas.inicial.contato.setores.departamentoDeRelacionamento'
                  )}
                </Option>
                <Option value="Assessoria de Imprensa">
                  {t('paginas.inicial.contato.setores.assessoriaDeImprensa')}
                </Option>
              </Select>
              {errors.setor && <DangerMessage>{errors.setor}</DangerMessage>}
            </Col>
            <Col md="6">
              <Textarea
                name="mensagem"
                placeholder={t('paginas.inicial.contato.mensagem')}
                onChange={(e) => handleChange(e)}
              />
              {errors.mensagem && (
                <DangerMessage>{errors.mensagem}</DangerMessage>
              )}
            </Col>
            <Col md="12">
              <Button type="submit">
                {t('paginas.inicial.contato.enviar')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      <style>{`
        .formGroup {
          width: 100%;
          margin-bottom: 15px;
          padding: 10px;
          border-color: #c11523;
          border-width: 1px;
          border-top-style: outset;
          border-left-style: outset;
          color: #c11523;
        }
        ::placeholder {
          color: #c11523;
          opacity: 0.8;
        }
        :-ms-input-placeholder {
          color: #c11523;
        }
        ::-ms-input-placeholder {
          color: #c11523;
        }
      `}</style>
    </>
  );
}

export default FormContato;
