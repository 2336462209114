import ptBrTraducoes from './pt-br';
import enUsTraducoes from './en';
import esTraducoes from './es';

const traducoes = {
  'pt-BR': ptBrTraducoes,
  en: enUsTraducoes,
  es: esTraducoes,
};

export default traducoes;

// const TRADUCOES_PT_BR = 'pt-BR';
// const TRADUCOES_EN = 'en';
// const TRADUCOES_ES = 'es';

// const gerarTraducoes = () => {
//   const traducoes = {
//     'pt-BR': {
//       traducoes: {},
//     },
//     en: {
//       traducoes: {},
//     },
//     es: {
//       traducoes: {},
//     },
//   };

//   const adicionarTraducao = (chave, traducaoPtBr, traducaoEn, traducaoEs) => {
//     traducoes[TRADUCOES_PT_BR].traducoes[`${chave}`] = traducaoPtBr;
//     traducoes[TRADUCOES_EN].traducoes[`${chave}`] = traducaoEn;
//     traducoes[TRADUCOES_ES].traducoes[`${chave}`] = traducaoEs;
//   };

//   const gerar = () => {
//     adicionarTraducao(
//       'cabecalho.painelExpositor',
//       'Painel Expositor',
//       'Panel de expositores',
//       'Exhibitor Panel'
//     );

//     console.log(traducoes);

//     return traducoes;
//   };

//   return gerar();
// };

// export default gerarTraducoes();
