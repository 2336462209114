import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import api from '@alweb-merkator/shared/services/api';

import {
  Col,
  Conteudo,
  Pilar,
  PilarConteudo,
  PilarTitulo,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoSubTitulo,
  SecaoTitulo,
  Tradutor,
  Video,
} from './styles';
import Head from '@alweb-merkator/shared/components/Head';
import ImagemQuemSomos from '../../../assets/quem-somos.png';

const SecaoQuemSomos = ({ idPage, ...props }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const secaoId = t('slugs.quemSomos');

  const [quemSomos, setQuemSomos] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    _handleGet();
  }, []);

  const _handleGet = async () => {
    await api.get(`/blnews/1`).then((response) => {
      const dados = response.data[0];
      const dado = {
        title: t('paginas.inicial.quemSomos.titulo'),
        content: t('paginas.inicial.quemSomos.conteudo'),
        description: t('paginas.inicial.quemSomos.subTitulo'),
        image: ImagemQuemSomos,
        video: t('paginas.inicial.quemSomos.video'),
        image_description: t('paginas.inicial.quemSomos.imagemDescricao'),
      };

      if (lang !== 'pt') {
        dado.title = dados['title_' + lang];
        dado.content = dados['content_' + lang];
        dado.description = dados['description_' + lang];
        dado.video = dados.video;
        dado.image = dados.image;
        dado.image_description = dados.image_description;
      } else {
        dado.title = dados.title;
        dado.content = dados.content;
        dado.description = dados.description;
        dado.video = dados.video;
        dado.image = dados.image;
        dado.image_description = dados.image_description;
      }
      setQuemSomos(dado);
      setIsLoading(false);
    });
  };

  const changeHead = () => (
    <Head title={quemSomos.title} description={quemSomos.description} />
  );

  return (
    <Secao {...props} id={secaoId}>
      {isLoading ? null : changeHead()}
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? '' : quemSomos.title} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <SecaoSubTitulo className="mb-4">
        {/* <Tradutor path={isLoading ? '' : quemSomos.description} /> */}
      </SecaoSubTitulo>
      <Row className="mb-4">
        <Col md="6">
          <Conteudo
            dangerouslySetInnerHTML={{
              __html: isLoading ? '' : quemSomos.content,
            }}
          />
        </Col>
        {isLoading ? null : (
          <Col md="6">
            <Video
              imagemPlaceholder={quemSomos.image}
              imageDescription={quemSomos.image_description}
              videoEmbedded={quemSomos.video}
            />
          </Col>
        )}
      </Row>
      {isLoading ? null : (
        <Row>
          <Col md="4" className="mb-4">
            <Pilar>
              <PilarTitulo>
                {t('paginas.inicial.quemSomos.pilares.negocio.titulo')}
              </PilarTitulo>
              <PilarConteudo>
                {t('paginas.inicial.quemSomos.pilares.negocio.conteudo')}
              </PilarConteudo>
            </Pilar>
          </Col>
          <Col md="4" className="mb-4">
            <Pilar>
              <PilarTitulo>
                {t('paginas.inicial.quemSomos.pilares.missao.titulo')}
              </PilarTitulo>
              <PilarConteudo>
                {t('paginas.inicial.quemSomos.pilares.missao.conteudo')}
              </PilarConteudo>
            </Pilar>
          </Col>
          <Col md="4" className="mb-4">
            <Pilar>
              <PilarTitulo>
                {t('paginas.inicial.quemSomos.pilares.valores.titulo')}
              </PilarTitulo>
              <PilarConteudo>
                {t('paginas.inicial.quemSomos.pilares.valores.conteudo')}
              </PilarConteudo>
            </Pilar>
          </Col>
        </Row>
      )}
    </Secao>
  );
};

SecaoQuemSomos.propTypes = {
  idPage: PropTypes.any,
};

export default SecaoQuemSomos;
