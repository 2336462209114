const traducoes = {
  traducoes: {
    slugs: {
      menu: 'menú',
      quemSomos: 'quienes-somos',
      nossosProdutos: 'nuestros-productos',
      sobreAFeira: 'sobre una feria',
      sicc: 'sicc',
      gramado: 'césped',
      serraPark: 'parque de la sierra',
      parceiros: 'socios',
      grupoDeImportadores: 'grupo-importador',
      estacao3: 'estacion-3',
      conectech: 'conectech',
      queroVisitar: 'quiero visitar',
      hospedagem: 'hospedaje',
      traslado: 'transferir',
      credenciamento: 'acreditación',
      agenciaDeTurismo: 'agencia de turismo',
      expositores: 'expositores',
      expositoresCategoriaFeminino: 'calcados-femininos',
      expositoresCategoriaMasculino: 'calcados-masculinos',
      expositoresCategoriaInfantil: 'calcados-infantis',
      expositoresCategoriaBolsasAcessorios: 'bolsas-e-acessorios',
      expositoresCategoriaEsportivos: 'calcados-esportivos',
      plantaBaixa: 'Planta baja',
      eMerkator: 'e-merkator',
      eMerkatorTalks: 'e-merkator-charlas',
      eMerkatorBlog: 'e-merkator-blog',
      eMerkatorTV: 'e-merkator-tv',
      eMerkatorCast: 'e-merkator-cast',
      eMerkatorPush: 'e-merkator-push',
      imprensa: 'Prensa',
      noticias: 'noticias',
      galeriaDeImagens: 'galería-de-imágenes',
      contato: 'contacto',
      programacoes: 'horarios',
    },
    cabecalho: {
      painelExpositor: 'Panel de expositores',
      menu: 'Menú',
      home: 'Inicio',
      quemSomos: 'Quiénes somos',
      nossosProdutos: 'Nuestros productos',
      sobreAFeira: 'Sobre la Feria',
      sicc: 'SICC',
      gramado: 'Césped',
      serraPark: 'Parque de la Serra',
      parceiros: 'Socios',
      grupoDeImportadores: 'Grupo de importadores',
      estacao3: 'Estación 3',
      conectech: 'Conectech',
      queroVisitar: 'quiero visitar',
      hospedagem: 'Alojamiento',
      traslado: 'Transferir',
      credenciamento: 'Acreditación',
      agenciaDeTurismo: 'Agencia de Turismo',
      expositores: 'Expositores',
      plantaBaixa: 'Planta baja',
      eMerkator: 'eMerkator',
      eMerkatorTalks: 'Charlas de eMerkator',
      eMerkatorBlog: 'Blog de eMerkator',
      eMerkatorTV: 'eMerkator TV',
      eMerkatorCast: 'eMerkator Cast',
      eMerkatorPush: 'eMerkator Push',
      imprensa: 'Prensa',
      noticias: 'Noticias',
      galeriaDeImagens: 'galería de imágenes',
      contato: 'Contacto',
    },
    paginas: {
      inicial: {
        blog: {
          titulo: 'Blog',
        },
        quemSomos: {
          titulo: 'Merkator Feiras',
          subTitulo:
            'A Merkator Feiras e Eventos é a realizadora do SICC - Salão Internacional do Couro e do Calçado, da Zero Grau - Feira de Calçados e Acessórios, da 40 Graus - Feira de Calçados e Acessórios. Fundada por Frederico Pletsch, no ano de 2003, a Merkator tem o compromisso de apresentar o que há de mais inovador e atualizado no seu mercado de atuação. Esta diretriz orienta todos os passos da empresa e foi responsável pela decisão de escolher locais que possibilitem unir turismo e negócios para sediar os seus eventos. Também foi o que propiciou o estabelecimento de uma associação de interesses comerciais com parceiros estratégicos, que dão sustentação às iniciativas da promotora.',
          conteudo:
            '<p>A Merkator Feiras e Eventos é a realizadora do SICC - Salão Internacional do Couro e do Calçado, da Zero Grau - Feira de Calçados e Acessórios, da 40 Graus - Feira de Calçados e Acessórios. Fundada por Frederico Pletsch, no ano de 2003, a Merkator tem o compromisso de apresentar o que há de mais inovador e atualizado no seu mercado de atuação. Esta diretriz orienta todos os passos da empresa e foi responsável pela decisão de escolher locais que possibilitem unir turismo e negócios para sediar os seus eventos. Também foi o que propiciou o estabelecimento de uma associação de interesses comerciais com parceiros estratégicos, que dão sustentação às iniciativas da promotora.</p>',
          video: '',
          pilares: {
            negocio: {
              titulo: 'Negocio',
              conteudo: 'Promover ferias y eventos.',
            },
            missao: {
              titulo: 'Misión',
              conteudo:
                'Promover ferias y eventos con el objetivo de proporcionar resultados a todos.',
            },
            valores: {
              titulo: 'Valores',
              conteudo: 'Honestidad, humildad, compromiso y unión.',
            },
          },
        },
        nossosProdutos: {
          titulo: 'Products',
          subTitulo: 'Feria de Calzados',
          produtos: {
            sicc: {
              titulo: 'SICC – Salón Internacional del Cuero y del Calzado',
              conteudo:
                '<p>Una de las principales ferias nacionales del segmento, el SICC es el evento donde las marcas de  calzado, bolsas y accesorios del país entero presentan  los lanzamientos de la moda primavera/verano al mercado.</p><p>Focalizado en la moda, es un evento esencialmente de negocios y atrae comerciantes de los cuatro rincones del país y del mundo, que vienen a conocer y nutrirse con las novedades.</p><p>El SICC es el palco  para que millares de marcas presenten sus colecciones en un escenario deslumbrante, con una vista de 360º para un área natural de 460 mil m2 , en Gramado, RS, uno de los puntos turísticos más visitados del Brasil.</p>',
            },
            quarentaGraus: {
              titulo: '40 Graus – Feria de Calzados y Accesorios',
              conteudo:
                '<p>En 2013, Merkator dió un paso importante para consolidar su actuación como promotora de ferias y lanzó 40 Graus en Natal, Rio Grande do Norte.</p><p>Juntas, la región norte y nordeste suman más de 60 millones de consumidores y reúnen algunas características auténticamente singulares de consumo.  La feria fue proyectada a partir de eso y buscó sincronizar el periodo de realización con las necesidades de compra de los comerciantes, que pueden de esta manera, abastecer sus stocks para un periodo muy alto de ventas: las fiestas juninas. Hoje 40 Graus es realizada en el Centro de Convenciones de João Pessoa. <br />www.feira40graus.com.br</p>',
            },
            zeroGraus: {
              titulo: 'Zero Grau – Feria de Calzados y Accesorios',
              conteudo:
                '<p>Éxito absoluto ya desde su primera edición, Zero Grau fue creada para completar  una laguna importante existente  en el calendario de la moda otoño/invierno. Realizada en noviembre, sincroniza el tiempo de producción fabril con las necesidades de abastecimiento del mercado minorista para el primer semestre del año; un periodo fundamental para las  ventas. La feria también es realizada en el Serra Park, en Gramado/ RS.</p>',
            },
          },
        },
        contato: {
          titulo: 'Contact',
          subTitulo: 'Rellene todos los campos',
          nome: 'Nombre',
          telefone: 'Teléfono',
          email: 'E-mail',
          assunto: 'Sujeto',
          setor: 'Sector',
          setores: {
            departamentoComercial: 'Departamento Comercial',
            departamentoFinanceiro: 'Departamento Financeiro',
            departamentoDeMarketing: 'Departamento De Marketing',
            departamentoDeRelacionamento: 'Departamento De Relacionamento',
            assessoriaDeImprensa: 'Assessoria De Imprensa',
          },
          mensagem: 'Mensaje',
          enviar: 'Enviar',
        },
        secaoSicc: {
          titulo: 'SICC - Salón Internacional del Cuero y Calzado',
          subTitulo: 'La nueva cara de la moda',
          botaoQueroVisitar: 'quiero visitar',
          botaoGaleria: 'Galería',
          conteudo: '',
        },
        secaoGramado: {
          titulo: 'Césped',
          conteudo: '',
        },
        secaoSerraPark: {
          titulo: 'Parque de la Serra',
          subTitulo: 'Viação Férrea, nº 100, Gramado / RS',
          conteudo: '',
          tituloCaracteristicas: 'Características',
          caracteristicas: '',
        },
        secaoParceiros: {
          titulo: 'Socios',
          conteudo: '',
        },
        secaoGrupoDeImportadores: {
          titulo: 'Grupo de importadores',
          conteudo: '',
        },
        secaoEstacao3: {
          titulo: 'Estación 3',
          conteudo: '',
        },
        secaoConectech: {
          titulo: 'Conectech',
          conteudo: '',
        },
        secaoQueroVisitar: {
          titulo: 'quiero visitar',
          hospedagem: {
            titulo: 'ALOJAMIENTO',
            conteudo:
              '<p> Merkator Feiras e Eventos, promotor de SICC pone a disposición & aacute; servicios de alojamiento en hotel en Serra Ga & uacute; cha, durante el período justo. <br /> Los amenities son exclusivos para minoristas del sector calista. </p>',
            textoBotao: 'Solicite cortesía aquí',
            subTextoBotao: '',
          },
          translado: {
            titulo: 'TRANSFERIR',
            conteudo:
              '<p> Traslado desde el Aeropuerto Internacional Salgado Filho, en Porto Alegre - Rio Grande do Sul, en & eacute; los hoteles acreditados serán acreditados; realizadas a través del transporte oficial de la SICC, con el objetivo de ofrecer mayor comodidad, confort y seguridad a nuestros visitantes. </p>',
            textoBotao: 'Tiempos y valores aquí',
            subTextoBotao: '',
          },
          credenciamento: {
            titulo: 'Acreditación',
            conteudo:
              '<p> Merkator quiere facilitar su entrada a la feria. Con este procedimiento tienes & aacute; una insignia & aacute; listo esperándote en la ventana & ecirc; de Acreditación Temprana y poder & aacute; Ingrese a la feria para conocer los principales lanzamientos primavera-verano en la industria de la cal y accesorios en Brasil. </p>',
            textoBotao: 'Registrarse aquí',
            subTextoBotao: 'Acreditación disponible próximamente',
          },
        },
        secaoAgenciaDeTurismo: {
          titulo: 'Agencia de turismo',
          conteudo:
            '<p> Sunpower Turismo, & eacute; agencia de viajes oficial de la feria. </p> <p> Cuenta con un equipo de profesionales calificados y con experiencia, brindando siempre un servicio personalizado de calidad, con un enfoque en la satisfacción del cliente. </p> <p> Ofrecemos todos los servicios de logística de viajes con descuentos especiales para los participantes / expositores de la feria. </p> <p> - Alojamiento; <br /> - Pasaje a & eacute; área; <br /> - Alquiler de coches. </p> <p> ¡Te recordamos que las reservas solicitadas con anticipación garantizan las mejores tarifas y condiciones! </p> <p> Más información & ccedil; & otilde; es, siga nuestros contactos: <br /> Sunpower Turismo <br /> +55 54-3286 5033 <br /> reservas@sunpowerturismo.com.br | www.sunpowerturismo.com.br </p>',
          subTitulo: 'Agencia Oficial de Turismo',
        },
        secaoExpositores: {
          titulo: 'Expositores',
          subTitulo: 'Seleccione el producto deseado:',
        },
        secaoPlantaDaFeira: {
          titulo: 'Planta baja',
        },
        secaoEMerkator: {
          titulo: 'eMerkator',
          subTitulo: 'Te conecta con el mercado',
          textoLinkProgramacao: 'Agenda completa',
        },
        secaoImprensa: {
          titulo: 'Prensa',
          noticias: 'Noticias',
          galeriaDeImagens: 'Galería de imágenes',
          falarComImprensa: 'Quiero hablar con la oficina de prensa',
        },
      },
      noticias: {
        secaoNoticias: {
          titulo: 'Noticias',
        },
      },
      noticia: {
        secaoNoticia: {
          titulo: 'Noticias',
          tituloNoticia: '',
          legendaImagem: '',
          textoTags: 'TAGS',
          conteudo: '',
        },
      },
      galerias: {
        titulo: 'Galerías',
      },
      programacoes: {
        secaoProgramacoes: {
          titulo: 'Horarios',
          diasSemana: {
            segunda: 'lunes',
            terca: 'martes',
            quarta: 'miércoles',
            quinta: 'jueves',
            sexta: 'viernes',
            sabado: 'sábado',
            domingo: 'domingo',
          },
        },
      },
      programacao: {
        secaoProgramacao: {
          titulo: 'Calendario',
        },
      },
      traslado: {
        titulo: 'Transferir',
      },
      expositores: {
        titulo: 'Expositores',
      },
      eMerkatorTv: {
        titulo: 'eMerkator TV',
      },
      pagina404: {
        titulo: 'Página no encontrada.',
      },
    },
    componentes: {
      painelExpositor: {
        email: 'Correo electrónico',
        senha: 'Contraseña',
        entrar: 'Enter',
        cancelar: 'Cancelar',
        esqueciMinhaSenha: 'Olvidé mi contraseña',
      },
      secao: {
        botaoVoltar: 'Regresar',
      },
      cabecalho: {
        linguagem: 'Idioma',
        portugues: 'portugués',
        ingles: 'inglés',
        espanhol: 'Español',
      },
      rodape: {
        baixeNossoApp: 'Disponible sur',
        disponivelNa: 'Disponible en',
      },
      secaoEMerkator: {
        programacaoCompleta: 'Pronto',
      },
    },
  },
};

export default traducoes;
