/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Galerias from '@alweb-merkator/shared/pages/GaleriasMekartor';
import Galeria from '@alweb-merkator/shared/pages/Galeria';
import Noticias from '@alweb-merkator/shared/pages/Noticias';
import Noticia from '@alweb-merkator/shared/pages/Noticia';
import EMerkatorTv from '@alweb-merkator/shared/pages/EMerkatorTv';
import Pagina404 from '@alweb-merkator/shared/pages/Pagina404';

import TemplateRoute from '@alweb-merkator/shared/routes/TemplateRoute';
import MultiLanguageRoute from '@alweb-merkator/shared/routes/MultiLanguageRoute';

import PaginaInicial from '../pages/PaginaInicial';
import PoliticaPrivacidade from '../pages/PoliticaPrivacidade';
import Template from '../Template';

const Routes = () => {
  const { t, i18n } = useTranslation();
  const renderComponent = (Comp) => {
    const renderTemplateRoute = (props) => (
      <TemplateRoute Template={Template} {...props} Comp={Comp} />
    );

    return renderTemplateRoute;
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const routes = useMemo(() => {
    const createRoute = (page, path, exact = false) => ({
      page,
      path,
      exact,
    });

    return [
      createRoute(PaginaInicial, '/:lang', true),
      createRoute(Galerias, `/:lang/${t('slugs.galeriaDeImagens')}`, true),
      createRoute(
        Galeria,
        `/:lang/${t('slugs.galeriaDeImagens')}/:categoriaSlug`
      ),
      createRoute(Noticias, `/:lang/${t('slugs.noticias')}`, true),
      createRoute(Noticia, `/:lang/${t('slugs.noticias')}/:noticiaSlug`),
      createRoute(EMerkatorTv, `/:lang/${t('slugs.eMerkatorTV')}`, true),
      createRoute(
        PoliticaPrivacidade,
        `/:lang/${t('slugs.politicaDePrivacidade')}`,
        true
      ),
      createRoute(Pagina404, '/:lang/*'),
    ];
  }, [t]);

  return (
    <Switch>
      <MultiLanguageRoute
        changeLanguage={changeLanguage}
        i18nCurrentLang={i18n.language}
        path="/"
        exact
      />
      {routes.map(({ page, path, exact }, key) => (
        <MultiLanguageRoute
          key={key}
          changeLanguage={changeLanguage}
          i18nCurrentLang={i18n.language}
          path={path}
          exact={exact}
          component={renderComponent(page)}
        />
      ))}
    </Switch>
  );
};

export default Routes;
