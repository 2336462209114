import React from 'react';
import { Row, Col } from 'reactstrap';
import { Secao } from '@alweb-merkator/shared/components/Secao';
import Slider from './Slider';
// import Noticias from './Noticias';
// import AssistirManifesto from './AssistirManifesto';

const SecaoBanner = ({ ...props }) => {
  return (
    <Secao {...props}>
      <Row>
        <Col md="12">
          <Slider />
          {/* <AssistirManifesto /> */}
        </Col>
        {/* <Col md="6">
          <Noticias />
        </Col> */}
      </Row>
    </Secao>
  );
};

export default SecaoBanner;
