export default {
  title: 'merkator',

  colors: {
    primary: '#C11523',
    secondary: '#707070',
    tertiary: '#f5a3aa',
    text: '#000000',
    textInBg: '#fff',
    borders: '#707070',
    borderBotaoEMerkator: '#ffffff',
    boxShadow: '#000000',
  },
  transparentColors: {
    primary: 'rgba(193,21,35,0.7)',
    secondary: 'rgba(112,112,112,0.7)',
  },
};
