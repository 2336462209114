/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import _ from 'lodash';

import ProdutoSicc from '../../assets/produto-sicc.png';
import ProdutoQuarentaGraus from '../../assets/produto-quarenta-graus.png';
import ProdutoZeroGrau from '../../assets/produto-zero-grau.png';

import blNewsService from '../../services/blNewsService';
import api from '../../services/apiSemCategoria';
import Head from '../../components/Head';

import {
  Link,
  Secao,
  SecaoCabecalho,
  SecaoMenu,
  SecaoMenuLista,
  SecaoMenuNav,
  SecaoTitulo,
  Tradutor,
  SecaoBotaoVoltar,
  SecaoMenuItem,
  Galeria,
} from './styles';

function Galerias() {
  const { t } = useTranslation();
  const { lang } = useParams();
  const [galeriasSicc, setGaleriasSicc] = useState([]);
  const [galeriasZeroGrau, setGaleriasZeroGrau] = useState([]);
  const [galeriasQuarentaGraus, setGaleriasQuarentaGraus] = useState([]);
  const [galeriasMerkator, setGaleriasMerkator] = useState([]);
  const { switchSizeImage } = blNewsService();

  useEffect(() => {
    manipularRepositorio(1, setGaleriasSicc);
  }, []);

  useEffect(() => {
    manipularRepositorio(2, setGaleriasZeroGrau);
  }, []);

  useEffect(() => {
    manipularRepositorio(3, setGaleriasQuarentaGraus);
  }, []);

  useEffect(() => {
    manipularRepositorio(4, setGaleriasMerkator);
  }, []);

  const manipularRepositorio = async (
    categoria,
    manipularGaleriasCarregadas
  ) => {
    let galeriasCarregadas = [];

    const carregarGalerias = async (pagina) => {
      const response = await api.get(
        '/blnews?tipo=3&page=' + pagina + '&categoria=' + categoria
      );

      galeriasCarregadas = [
        ...galeriasCarregadas,
        ...response.data._embedded.post.map((data) => ({
          titulo: data.title,
          id: data.id,
          imagem: data.image,
          slug: data.slug,
        })),
      ];

      if (response.data.page_count > response.data.page) {
        manipularGaleriasCarregadas(galeriasCarregadas);
        await carregarGalerias(response.data.page + 1);
      }
    };

    await carregarGalerias(1);
  };

  function renderGaleria(galeria, galeriaIdx) {
    const bgImage = switchSizeImage(galeria.imagem, 'sm');
    return (
      <Link
        className="galery_card"
        to={`/${lang}/${t('slugs.galeriaDeImagens')}/${galeria.slug}`}
      >
        <div
          className="galery_img"
          style={{ backgroundImage: `url(${bgImage})` }}
        />
        <div className="galery_overlay">
          <div className="galery_text">{galeria.titulo}</div>
        </div>
      </Link>
    );
  }

  function renderAlbum(album) {
    const classStyle =
      album.evento === 'quarenta'
        ? 'galery_img_custom_40'
        : 'galery_img_custom';
    return (
      <div className="galery_container">
        <div className="galery_album_card">
          {album.titulo ? (
            <div className="galery_text">{album.titulo}</div>
          ) : null}
          {album.imagem ? (
            <div
              className={classStyle}
              style={{ backgroundImage: `url(${album.imagem})` }}
            ></div>
          ) : null}
        </div>
        <div className="cards">
          {album.galerias.map((galeria, galeriaIdx) =>
            renderGaleria(galeria, galeriaIdx)
          )}
        </div>
      </div>
    );
  }

  return (
    <Secao className="mb-4">
      <Head
        title={t('paginas.galerias.titulo')}
        description={t('paginas.galerias.titulo')}
      />
      <SecaoCabecalho className="mb-5">
        <SecaoTitulo>
          <Tradutor path="paginas.galerias.titulo" />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              <SecaoMenuItem>
                <SecaoBotaoVoltar />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>
      <Galeria>
        {renderAlbum({
          imagem: ProdutoSicc,
          galerias: galeriasSicc,
          evento: 'sicc',
        })}
        {renderAlbum({
          imagem: ProdutoQuarentaGraus,
          galerias: galeriasQuarentaGraus,
          evento: 'quarenta',
        })}
        {renderAlbum({
          imagem: ProdutoZeroGrau,
          galerias: galeriasZeroGrau,
          evento: 'zero',
        })}
      </Galeria>
    </Secao>
  );
}

export default Galerias;
