const traducoes = {
  traducoes: {
    slugs: {
      menu: 'menu',
      quemSomos: 'about-us',
      nossosProdutos: 'products',
      sobreAFeira: 'about-a-feira',
      sicc: 'sicc',
      gramado: 'lawn',
      serraPark: 'serra-park',
      parceiros: 'partners',
      grupoDeImportadores: 'importer-group',
      estacao3: 'station-3',
      conectech: 'conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'hosting',
      traslado: 'transfer',
      credenciamento: 'accreditation',
      agenciaDeTurismo: 'agencia-de-turismo',
      expositores: 'exhibitors',
      expositoresCategoriaFeminino: 'calcados-femininos',
      expositoresCategoriaMasculino: 'calcados-masculinos',
      expositoresCategoriaInfantil: 'calcados-infantis',
      expositoresCategoriaBolsasAcessorios: 'bolsas-e-acessorios',
      expositoresCategoriaEsportivos: 'calcados-esportivos',
      plantaBaixa: 'floor plan',
      eMerkator: 'e-merkator',
      eMerkatorTalks: 'e-merkator-talks',
      eMerkatorBlog: 'e-merkator-blog',
      eMerkatorTV: 'e-merkator-tv',
      eMerkatorCast: 'e-merkator-cast',
      eMerkatorPush: 'e-merkator-push',
      imprensa: 'press',
      noticias: 'news',
      galeriaDeImagens: 'image-gallery',
      contato: 'contact',
      programacoes: 'schedules',
    },
    cabecalho: {
      painelExpositor: 'Exhibitor Panel',
      menu: 'Menu',
      home: 'Home',
      quemSomos: 'About Us',
      nossosProdutos: 'Products',
      sobreAFeira: 'About the Fair',
      sicc: 'SICC',
      gramado: 'Lawn',
      serraPark: 'Serra Park',
      parceiros: 'Partners',
      grupoDeImportadores: 'Importer Group',
      estacao3: 'Station 3',
      conectech: 'Conectech',
      queroVisitar: 'I want to visit',
      hospedagem: 'Accommodation',
      traslado: 'Transfer',
      credenciamento: 'Accreditation',
      agenciaDeTurismo: 'Tourism Agency',
      expositores: 'Exhibitors',
      plantaBaixa: 'Floor Plan',
      eMerkator: 'eMerkator',
      eMerkatorTalks: 'eMerkator Talks',
      eMerkatorBlog: 'eMerkator Blog',
      eMerkatorTV: 'eMerkator TV',
      eMerkatorCast: 'eMerkator Cast',
      eMerkatorPush: 'eMerkator Push',
      imprensa: 'Press',
      noticias: 'News',
      galeriaDeImagens: 'Image Gallery',
      contato: 'Contact',
    },
    paginas: {
      inicial: {
        blog: {
          titulo: 'Blog',
        },
        quemSomos: {
          titulo: 'Merkator Feiras',
          subTitulo:
            'A Merkator Feiras e Eventos é a realizadora do SICC - Salão Internacional do Couro e do Calçado, da Zero Grau - Feira de Calçados e Acessórios, da 40 Graus - Feira de Calçados e Acessórios. Fundada por Frederico Pletsch, no ano de 2003, a Merkator tem o compromisso de apresentar o que há de mais inovador e atualizado no seu mercado de atuação. Esta diretriz orienta todos os passos da empresa e foi responsável pela decisão de escolher locais que possibilitem unir turismo e negócios para sediar os seus eventos. Também foi o que propiciou o estabelecimento de uma associação de interesses comerciais com parceiros estratégicos, que dão sustentação às iniciativas da promotora.',
          conteudo:
            '<p>A Merkator Feiras e Eventos é a realizadora do SICC - Salão Internacional do Couro e do Calçado, da Zero Grau - Feira de Calçados e Acessórios, da 40 Graus - Feira de Calçados e Acessórios. Fundada por Frederico Pletsch, no ano de 2003, a Merkator tem o compromisso de apresentar o que há de mais inovador e atualizado no seu mercado de atuação. Esta diretriz orienta todos os passos da empresa e foi responsável pela decisão de escolher locais que possibilitem unir turismo e negócios para sediar os seus eventos. Também foi o que propiciou o estabelecimento de uma associação de interesses comerciais com parceiros estratégicos, que dão sustentação às iniciativas da promotora.</p>',
          video: '',
          pilares: {
            negocio: {
              titulo: 'Business',
              conteudo: 'To promote fairs and events.',
            },
            missao: {
              titulo: 'Mission',
              conteudo:
                'To promote fairs and events with the goal of providing results to all.',
            },
            valores: {
              titulo: 'Values',
              conteudo: 'Honesty, humility, commitment and union.',
            },
          },
        },
        nossosProdutos: {
          titulo: 'Products',
          subTitulo: 'Fair of Footwear',
          produtos: {
            sicc: {
              titulo: 'SICC - International Leather and Footwear Exhibition',
              conteudo:
                '<p>One of the main national trade fair of the segment, SICC is the event where the brands of shoes, bags and accessories from around the country show their fashion spring / summer launches to the market. Focused on fashion, it is an event essentially for business and attracts retailers from the 4 corners of the country and the world who come to meet and feed up with the news. SICC is the host to thousands of brands that present their collections in stunning scenery, with a view of 360 ° to a natural area of 460 thousand m2 in Gramado, RS, one of the most visited cities of Brazil.</p>',
            },
            quarentaGraus: {
              titulo: '40 Graus - Fair of Footwear and Accessories',
              conteudo:
                '<p>In 2013, Merkator took another important step to solidify its operation as fair promoter and launched 40 Graus in Natal, Rio Grande do Norte. Together, the northern and northeastern regions account for more than 60 million consumers and meet some very specific characteristics of consumption. The fair was designed on top of that and tried to tune the period of realization with the purchasing needs of the retailers, who can thus supply their stocks to a very hot sales period: The June Festival. Today, the 40 Graus is performed in the Convention Centre of João Pessoa.</p>',
            },
            zeroGraus: {
              titulo: 'Zero Grau - Fair of Footwear and Accessories',
              conteudo:
                '<p>Absolute success already in its premiere edition, Zero Grau was created to complete an important gap in the fashion calendar for autumn / winter. Held in November, it synchronizes the time of production of the factories with the need of supply to the retailers in the first half of the year, a key sales period. The fair is also held at Serra Park in Gramado / RS.</p>',
            },
          },
        },
        contato: {
          titulo: 'Contact',
          subTitulo: 'Fill out all Fields',
          nome: 'Name',
          telefone: 'Phone',
          email: 'E-mail',
          assunto: 'Subject',
          setor: 'Sector',
          setores: {
            departamentoComercial: 'Commercial Deptament',
            departamentoFinanceiro: 'Finantial Departament',
            departamentoDeMarketing: 'Marketing Departament',
            departamentoDeRelacionamento: 'Relationship Departament',
            assessoriaDeImprensa: 'Press',
          },
          mensagem: 'Message',
          enviar: 'Send',
        },
        secaoSicc: {
          titulo: 'SICC - International Leather and Footwear Exhibition',
          subTitulo: 'The new face of fashion',
          botaoQueroVisitar: 'I want to visit',
          botaoGaleria: 'Gallery',
          conteudo: '',
        },
        secaoGramado: {
          titulo: 'Gramado',
          conteudo: '',
        },
        secaoSerraPark: {
          titulo: 'Serra Park',
          subTitulo: 'Viação Férrea, nº 100, Gramado/RS',
          conteudo: '',
          tituloCaracteristicas: 'Features',
          caracteristicas: '',
        },
        secaoParceiros: {
          titulo: 'Partners',
          conteudo: '',
        },
        secaoGrupoDeImportadores: {
          titulo: 'Importers Group',
          conteudo: '',
        },
        secaoEstacao3: {
          titulo: 'Station 3',
          conteudo: '',
        },
        secaoConectech: {
          titulo: 'Conectech',
          conteudo: '',
        },
        secaoQueroVisitar: {
          titulo: 'I want to visit',
          hospedagem: {
            titulo: 'ACCOMMODATION',
            conteudo:
              '<p> Merkator Feiras e Eventos, promoter of SICC make available & aacute; hotel accommodation amenities in Serra Ga & uacute; cha, during the fair period. <br /> The amenities are exclusive for retailers in the calista sector. </p>',
            textoBotao: 'Request courtesy here',
            subTextoBotao: '',
          },
          translado: {
            titulo: 'TRANSFER',
            conteudo:
              '<p> Transfer from Salgado Filho International Airport, in Porto Alegre - Rio Grande do Sul, at & eacute; accredited hotels will be accredited; carried out through the official transport of the SICC, with the objective of offering greater convenience, comfort and safety to our visitors. </p>',
            textoBotao: 'Times and values here',
            subTextoBotao: '',
          },
          credenciamento: {
            titulo: 'ACCREDITATION',
            conteudo:
              '<p> Merkator wants to facilitate your entry to the fair. With this procedure you have & aacute; a badge & aacute; ready waiting for you at the window & ecirc; of Early Accreditation and power & aacute; enter the fair to learn about the main spring-summer launches in the lime and accessories industries in Brazil. </p>',
            textoBotao: 'Register here',
            subTextoBotao: 'Accreditation available soon',
          },
        },
        secaoAgenciaDeTurismo: {
          titulo: 'Tourism agency',
          conteudo:
            "<p> Sunpower Turismo, & eacute; the fair's official travel agency. </p> <p> It has a team of qualified and experienced professionals, always providing a quality, personalized service, with a focus on customer satisfaction. </p> <p> We offer all travel logistic services with special discounts for the fair's participants / exhibitors. </p> <p> - Accommodation; <br /> - Passage to & eacute; area; <br /> - Car rental. </p> <p> We remind you that reservations requested in advance guarantee the best rates and conditions! </p> <p> More information & ccedil; & otilde; es, follow our contacts: <br /> Sunpower Turismo <br /> +55 54-3286 5033 <br /> reservas@sunpowerturismo.com.br | www.sunpowerturismo.com.br </p>",
          subTitulo: 'Official Tourism Agency',
        },
        secaoExpositores: {
          titulo: 'Exhibitors',
          subTitulo: 'Select the desired product:',
        },
        secaoPlantaDaFeira: {
          titulo: 'Floor plan',
        },
        secaoEMerkator: {
          titulo: 'eMerkator',
          subTitulo: 'Connects you to the market',
          textoLinkProgramacao: 'Full schedule',
        },
        secaoImprensa: {
          titulo: 'Press',
          noticias: 'News',
          galeriaDeImagens: 'Image gallery',
          falarComImprensa: 'I want to speak to the press office',
        },
      },
      noticias: {
        secaoNoticias: {
          titulo: 'News',
        },
      },
      noticia: {
        secaoNoticia: {
          titulo: 'News',
          tituloNoticia:
            'Challenging year: promoter transits in virtual and in-person environments to hold events before and during the pandemic',
          legendaImagem:
            'Frederico at a press conference at Zero Grau 2020 - Photo: Dinarci Borges',
          textoTags: 'TAGS',
          conteudo: '',
        },
      },
      galerias: {
        titulo: 'Galleries',
      },
      programacoes: {
        secaoProgramacoes: {
          titulo: 'Schedules',
          diasSemana: {
            segunda: 'Monday',
            terca: 'Tuesday',
            quarta: 'Wednesday',
            quinta: 'Thursday',
            sexta: 'Friday',
            sabado: 'Saturday',
            domingo: 'Sunday',
          },
        },
      },
      programacao: {
        secaoProgramacao: {
          titulo: 'Schedule',
        },
      },
      traslado: {
        titulo: 'Transfer',
      },
      expositores: {
        titulo: 'Exhibitors',
      },
      eMerkatorTv: {
        titulo: 'eMerkator TV',
      },
      pagina404: {
        titulo: 'Page not found.',
      },
    },
    componentes: {
      painelExpositor: {
        email: 'Email',
        senha: 'Password',
        entrar: 'Enter',
        cancelar: 'Cancel',
        esqueciMinhaSenha: 'I forgot my password',
      },
      secao: {
        botaoVoltar: 'Back',
      },
      cabecalho: {
        linguagem: 'Language',
        portugues: 'Portuguese',
        ingles: 'English',
        espanhol: 'Spanish',
      },
      rodape: {
        baixeNossoApp: 'Download on the',
        disponivelNa: 'Available in',
      },
      secaoEMerkator: {
        // programacaoCompleta: 'Full schedule',
        programacaoCompleta: 'Coming soon',
      },
    },
  },
};

export default traducoes;
