import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

export {
  Secao,
  SecaoTitulo,
  SecaoSubTitulo,
  SecaoBotaoVoltar,
  SecaoCabecalho,
} from '@alweb-merkator/shared/components/Secao';

export { default as Video } from '@alweb-merkator/shared/components/Video';

export { Col, Row } from 'reactstrap';

export { Tradutor } from '@alweb-merkator/shared/components/I18n';

export const Produto = styled.div`
  min-height: 100%;
  padding: 15px;
`;

export const LinkProduto = styled.a`
  text-decoration: none;
`;

export const ImagemComOverlay = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const ImagemPlaceholder = styled.img`
  width: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.transparentColors.primary};
`;

export const ImagemOverlay = styled.img`
  position: absolute;
  opacity: 1;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ProdutoConteudo = styled.p`
  color: ${(props) => props.theme.colors.text};
`;

export const BotaoLink = styled(Link)`
  line-height: 2em;

  font-size: 1.3rem;

  @media (min-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const Conteudo = styled.div`
  @media (min-width: 768px) {
    padding-right: 10%;
  }

  @media (min-width: 992px) {
    padding-right: 15%;
  }

  @media (min-width: 1200px) {
    padding-right: 20%;
  }
`;
