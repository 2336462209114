import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from '../../../components/Loader';
import { StoreContext } from '../../../store';
import api from '../../../services/api';
import placeholderNewsHome260x128 from '../../../assets/placeholder-news-home-260x128.svg';
import blNewsService from '../../../services/blNewsService';
import {
  Col,
  Row,
  SecaoMenu,
  SecaoMenuNav,
  SecaoMenuLista,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoTitulo,
  Tradutor,
  SecaoMenuItem,
  SubTitulo,
  Noticia,
  NoticiaImagem,
  NoticiaTitulo,
  GridImagens,
  ImagemResponsiva,
  QueroFalarComAcessoria,
  SectionCenter,
} from './styles';

function SecaoImprensa() {
  const { dispatch } = useContext(StoreContext);
  const { t } = useTranslation();
  const { lang } = useParams();
  const secaoId = t('slugs.imprensa');
  const [post, setPost] = useState('');
  const [gallery, setGallery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGallery, setIsLoadingGallery] = useState(false);
  const { switchSizeImage } = blNewsService();

  useEffect(() => {
    handlePost();
    handleGallery();
    return () => {};
  }, []);

  const handlePost = async () => {
    await api.get('blnews?tipo=2').then((response) => {
      const posts = [...response.data._embedded.post];
      posts.map((dados) => {
        const dado = {
          title: '',
          description: '',
          slug: dados.slug,
          image: dados.image,
          image_description: dados.image_description,
        };

        if (lang !== 'pt') {
          dado.title = dados['title_' + lang];
          dado.description = dados['description_' + lang];
        } else {
          dado.title = dados.title;
          dado.description = dados.description;
        }

        return dado;
      });

      setPost(posts);
      handleSetPostStore(posts);
      setIsLoading(true);
    });
  };
  const handleGallery = async () => {
    await api.get('blnews?tipo=3').then((response) => {
      setGallery(response.data._embedded.post);
      handleSetGalleryStore(response.data._embedded.post);
      setIsLoadingGallery(true);
    });
  };
  const handleSetPostStore = (post) => {
    dispatch({ type: 'POST_ADD', payload: { data: post } });
  };
  const handleSetGalleryStore = (gallery) => {
    dispatch({ type: 'GALLERY_ADD', payload: { data: gallery } });
  };
  return (
    <Secao id={secaoId} className="mb-4">
      <SecaoCabecalho className="mb-4">
        <SecaoTitulo>
          <Tradutor path="paginas.inicial.secaoImprensa.titulo" />
        </SecaoTitulo>
        <SecaoMenu>
          <SecaoMenuNav>
            <SecaoMenuLista>
              <SecaoMenuItem />
              <SecaoMenuItem>
                <a
                  href="https://blog.merkatorfeiras.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  eMerkator Blog
                </a>
              </SecaoMenuItem>
              <SecaoMenuItem>
                Marta Araújo Conteúdos Jornalísticos (55 51) 99365-3864
              </SecaoMenuItem>
              <SecaoMenuItem>
                <SecaoBotaoVoltar />
              </SecaoMenuItem>
            </SecaoMenuLista>
          </SecaoMenuNav>
        </SecaoMenu>
      </SecaoCabecalho>

      <Row>
        <Col md="6">
          <Link
            to={`/${lang}/${t('slugs.noticias')}`}
            title={t('paginas.inicial.secaoImprensa.noticias')}
          >
            <SubTitulo>{t('paginas.inicial.secaoImprensa.noticias')}</SubTitulo>
          </Link>
          {!isLoading && (
            <SectionCenter>
              <Loader />
            </SectionCenter>
          )}
          {isLoading &&
            post.slice(0, 3).map((news, idxNoticia) => (
              <Noticia key={idxNoticia}>
                <Link
                  to={`/${lang}/${t('slugs.noticias')}/${news.slug}`}
                  title={news.description}
                >
                  <NoticiaImagem
                    style={{
                      backgroundImage: `url(${
                        news.image.length
                          ? switchSizeImage(news.image, 'sm')
                          : placeholderNewsHome260x128
                      })`,
                    }}
                    alt={news.image_description}
                  />
                  <NoticiaTitulo>{news.title}</NoticiaTitulo>
                </Link>
              </Noticia>
            ))}
        </Col>
        <Col md="6">
          <Link
            to={`/${lang}/${t('slugs.galeriaDeImagens')}`}
            title={t('paginas.inicial.secaoImprensa.galeriaDeImagens')}
          >
            <SubTitulo>
              {t('paginas.inicial.secaoImprensa.galeriaDeImagens')}
            </SubTitulo>
          </Link>
          {!isLoadingGallery && (
            <SectionCenter>
              <Loader />
            </SectionCenter>
          )}
          <GridImagens>
            {isLoadingGallery &&
              gallery.slice(0, 6).map((dataGallery, idxGallery) => (
                <Link
                  to={`/${lang}/${t('slugs.galeriaDeImagens')}/${
                    dataGallery.slug
                  }`}
                  title={dataGallery.description}
                  key={idxGallery}
                >
                  <ImagemResponsiva
                    style={{
                      backgroundImage: `url(${
                        dataGallery.image.length
                          ? switchSizeImage(dataGallery.image, 'sm')
                          : placeholderNewsHome260x128
                      })`,
                    }}
                    alt={dataGallery.image_description}
                  />
                </Link>
              ))}
          </GridImagens>
          <QueroFalarComAcessoria
            href="https://merkatorfeiras.com.br/contato"
            target="_blank"
          >
            {t('paginas.inicial.secaoImprensa.falarComImprensa')}
          </QueroFalarComAcessoria>
        </Col>
      </Row>
    </Secao>
  );
}

export default SecaoImprensa;
