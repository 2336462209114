import React from 'react';
import {
  Col,
  Container,
  FooterStyled,
  Informacoes,
  Logo,
  MediaLink,
  Row,
  Icon,
  MediaLinkText,
} from './styles';
import {
  RiFacebookLine,
  RiInstagramLine,
  RiTwitterLine,
  RiLinkedinLine,
  RiYoutubeFill,
} from 'react-icons/ri';
import ImagemLogoRodape from '../../assets/logo-footer-slogan-2023.svg';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import Aplicativos from '@alweb-merkator/shared/components/RodapeFeiras/Aplicativos';

function Rodape() {
  const aplicativos = [
    {
      Icone: FaApple,
      textoNomeLoja: 'App Store',
      linkApp: '//apps.apple.com/us/app/merkator-feiras-e-eventos/id1487335018',
    },
    {
      Icone: FaGooglePlay,
      textoNomeLoja: 'Google Play',
      linkApp:
        '//play.google.com/store/apps/details?id=com.merkatorvisitante&hl=pt_BR&gl=US',
    },
  ];

  return (
    <FooterStyled>
      <Container fluid>
        <Row>
          <Col md="4">
            <Logo src={ImagemLogoRodape} alt="Logo" />
            <Informacoes>
              Rua Bento Gonçalves 2310/104
              <br />
              Centro – Novo Hamburgo – RS
              <br />
              CEP: 93510-018
              <br />
              Fone: 55 51 – 3593-7889 / 3527-0362 / 3594-1346 / 3527-0451
              <br />
            </Informacoes>
          </Col>
          <Col md="1"></Col>
          <Col md="7">
            <Row>
              <Col md="4">
                <MediaLink
                  href="https://www.instagram.com/merkatorfeira/"
                  target="_blank"
                >
                  <Icon>
                    <RiInstagramLine />
                  </Icon>
                  <MediaLinkText>MERKATORFEIRA</MediaLinkText>
                </MediaLink>
                <MediaLink
                  href="https://www.facebook.com/merkatorfeira"
                  target="_blank"
                >
                  <Icon>
                    <RiFacebookLine />
                  </Icon>
                  <MediaLinkText>MERKATORFEIRA</MediaLinkText>
                </MediaLink>
                <MediaLink
                  href="https://twitter.com/merkatorfeiras"
                  target="_blank"
                >
                  <Icon>
                    <RiTwitterLine />
                  </Icon>
                  <MediaLinkText>MERKATORFEIRAS</MediaLinkText>
                </MediaLink>
              </Col>
              <Col md="4">
                <MediaLink
                  href="https://www.linkedin.com/company/merkatorfeira/"
                  target="_blank"
                >
                  <Icon>
                    <RiLinkedinLine />
                  </Icon>
                  <MediaLinkText>MERKATORFEIRAS</MediaLinkText>
                </MediaLink>
                <MediaLink
                  href="https://www.youtube.com/c/EmotionTV"
                  target="_blank"
                >
                  <Icon>
                    <RiYoutubeFill />
                  </Icon>
                  <MediaLinkText>EMERKATORTV</MediaLinkText>
                </MediaLink>
              </Col>
              <Col md="4">
                <Aplicativos itens={aplicativos} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FooterStyled>
  );
}

export default Rodape;
